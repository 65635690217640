$fa-font-path: "../webfonts";

$font-primary: "Roboto", sans-serif;
$font-secondary: "Rubik", sans-serif;

$color-primary: #2fa8b0;
$color-primary-dark: #1c6469;
$color-secondary: #6a2c73;
$color-secondary-dark: #151c52;

$pretty--color-default: #ced4da;
$pretty--color-primary: #2fa8b0;

:root {
    --animate-duration: 500ms;
    --animate-delay: 0.2s;
}
