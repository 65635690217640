.font-10 {
    font-size: 10px;
}

.font-12 {
    font-size: 12px;
}

.font-14 {
    font-size: 14px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.clear {
    clear: both;
}

.asterisk_input {
    color: inherit;
    font-weight: bold;
}

.asterisk_input::after {
    content: " *";
    color: #e32;
}

.font-weight-medium {
    font-weight: 500;
}

.font-weight-minimal {
    font-weight: 300;
}

.display-5 {
    font-size: 3rem;
}

.display-6 {
    font-size: 2.5rem;
}

.display-7 {
    font-size: 2rem;
}

.round {
    line-height: 45px;
    width: 45px;
    height: 45px;
}

.round-lg {
    line-height: 65px;
    width: 60px;
    height: 60px;
    font-size: 30px;
}

.purple {
    color: $color-secondary !important;
}
