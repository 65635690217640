.right-part.chat-container,
.right-part.invoice-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.right-part.chat-container .chat-box-inner-part,
.right-part.invoice-box .chat-box-inner-part {
    width: 100%;
}

.left-part.list-of-user,
.left-part.user-chat-box {
    height: 100vh;
}

.left-part {
    position: absolute;
    height: 100%;
    width: 260px;
    border-right: 1px solid #e9ecef;
}

.left-part.fixed-left-part {
    position: fixed;
    top: 0;
    padding-top: 10px;
}

.left-part .show-left-part {
    position: absolute;
    top: 70%;
    right: -60px;
}

.right-part {
    width: calc(100% - 260px);
    height: calc(100vh - 125px);
    margin-left: 260px;
}

.reverse-mode .left-part {
    right: 0;
    border-left: 1px solid #e9ecef;
}

.reverse-mode .show-left-part {
    right: auto;
    left: -41px;
}

.reverse-mode .right-part {
    margin-left: 0;
    margin-right: 260px;
}

.todo-list li .assignedto li {
    margin-right: 2px;
}

.todo-list li .assignedto li img {
    width: 30px;
}

.list-task .task-done span {
    text-decoration: line-through;
}

.todo-listing .todo-item .inner-item.done-todo .todo-subtext,
.todo-listing .todo-item .inner-item.done-todo .todo-time,
.todo-listing .todo-item .inner-item.done-todo label {
    text-decoration: line-through;
}

.todo-listing .todo-item .todo-action-dropdown.dropdown-toggle::after {
    display: none;
}

.todo-listing .todo-item.current-task-done .todo-header,
.todo-listing .todo-item.current-task-done .todo-subtext,
.todo-listing .todo-item.current-task-done .todo-time {
    text-decoration: line-through;
}

.todo-listing .todo-item.all-todo-list .todo-action-dropdown .dropdown-item.permanent-delete,
.todo-listing .todo-item.all-todo-list .todo-action-dropdown .dropdown-item.revive {
    display: none;
}

.todo-listing .todo-item.current-todo-delete {
    display: none;
}

.todo-listing .todo-item.current-todo-delete .todo-action-dropdown .dropdown-item.permanent-delete,
.todo-listing .todo-item.current-todo-delete .todo-action-dropdown .dropdown-item.revive {
    display: block;
}

.todo-listing .todo-item.current-todo-delete .todo-action-dropdown .dropdown-item.edit,
.todo-listing .todo-item.current-todo-delete .todo-action-dropdown .dropdown-item.important,
.todo-listing .todo-item.current-todo-delete .todo-action-dropdown .dropdown-item.remove {
    display: none;
}

@include media-breakpoint-down(md) {
    .left-part {
        position: fixed;
        left: -260px;
        top: 70px;
        background: #f2f7f8;
        z-index: 1;
        transition: 0.1s ease-in;
    }

    .left-part.show-panel {
        left: 0;
        top: 70px;
    }
    .reverse-mode .left-part {
        right: -260px;
        left: auto;
    }
    .reverse-mode .left-part.show-panel {
        right: 0;
    }
    .right-part {
        width: 100%;
        margin-left: 0;
    }
}
