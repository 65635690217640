.dashboard-calendar .fc-scroller {
    overflow: hidden !important;
}

.fc-view {
    margin-top: 30px;
}

.fc-toolbar {
    margin-bottom: 5px;
    margin-top: 15px;
}

.fc-toolbar h2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    text-transform: uppercase;
}

.fc-day {
    background: #fff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar .ui-state-hover,
.fc-toolbar button:focus,
.fc-toolbar button:hover {
    z-index: 0;
}

.fc-widget-header {
    border: 0 !important;
}

.fc-widget-content {
    border-color: rgba(120, 130, 140, 0.13) !important;
}

.fc th.fc-widget-header {
    color: #54667a;
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: 300;
    padding: 7px 0;
}

.fc th.fc-fri,
.fc th.fc-mon,
.fc th.fc-sat,
.fc th.fc-sun,
.fc th.fc-thu,
.fc th.fc-tue,
.fc th.fc-wed {
    background: #f2f7f8;
}

.fc-view {
    margin-top: 0;
}

.fc-toolbar {
    margin: 0;
    padding: 24px 0;
}

.fc-button {
    background: #fff;
    border: 1px solid #e9ecef;
    color: #54667a;
    text-transform: capitalize;
}

.fc-button:hover {
    background: #ebf3f5;
    opacity: 0.8;
}

.fc-text-arrow {
    font-size: 16px;
    font-family: inherit;
}

.fc-state-hover {
    background: #f5f5f5;
}

.fc-unthemed .fc-today {
    border: 1px solid #f62d51;
    background: #ebf3f5 !important;
}

.fc-cell-overlay,
.fc-state-highlight {
    background: #f0f0f0;
}

.fc-unthemed .fc-today {
    background: #fff;
}

.fc-event {
    border-radius: 0;
    border: none;
    background: $color-primary;
    color: #fff !important;
    padding: 5px 5px;
    margin: 1px -1px 0 -1px;
    cursor: move;
    font-size: 13px;
    text-align: center;
}

.fc-event:hover {
    background: $color-primary;
}

.fc-basic-view td.fc-week-number span {
    padding-right: 5px;
}

.fc-basic-view .fc-day-number {
    display: inline-block;
    padding: 10px 15px;
}

.fc-state-default {
    text-shadow: none;
    box-shadow: none;
}

.fc-basic-view .fc-body .fc-row {
    min-height: 5rem;
}

.fc-toolbar .fc-button-group {
    border: 1px solid $color-primary;
    border-radius: 4px;
}

.fc-toolbar .fc-button {
    background: $color-primary;
    color: #fff;
    border: none;
}

.fc-toolbar .fc-button:hover {
    background: $color-primary;
    opacity: 1;
}

.fc-toolbar .fc-button.fc-state-active {
    background: #fff;
    color: $color-primary;
}

.fc-widget-header {
    border: 0 !important;
}

.fc-widget-content {
    border-color: rgba(120, 130, 140, 0.13) !important;
}

.fc-widget-content tr {
    border-bottom: none;
}

.fc-view {
    margin-top: 0;
}

.fc-toolbar {
    margin: 0;
    padding: 24px 0;
}

.fc-header-toolbar {
    padding: 0;
}

.fc-list-item.bg-primary {
    color: silver;
}

.calendar {
    margin-bottom: 0;
}

.calendar-event {
    margin: 10px 5px 0 0;
    padding: 6px 10px;
    display: inline-block;
    cursor: move;
    color: #fff;
    text-align: center;
    min-width: 140px;
    background: $color-primary;
}

.calendar-event a {
    opacity: 0.6;
    font-size: 10px;
    color: #fff;
    margin: 4px 0 0 10px;
}

@include media-breakpoint-down(md) {
    .fc .fc-toolbar {
        display: block !important;
    }
    .fc .fc-daygrid-event-dot {
        display: none;
    }
    .fc .fc-event-time,
    .fc .fc-event-title {
        flex: 0 0 100%;
    }
    .fc .fc-event-time {
        border-bottom: 1px solid #fff;
        margin-bottom: 0.125rem;
    }
    .fc .fc-daygrid-body-natural .fc-daygrid-day-events {
        margin-bottom: 0.5rem !important;
    }
    .fc .fc-daygrid-event {
        font-size: 12px;
        white-space: normal;
        flex-wrap: wrap;
        flex-direction: row;
        text-align: center;
        justify-content: space-between;
    }
    .fc-toolbar-chunk {
        width: 100%;
    }
}

@media (min-width: 1800px) {
    .dashboard-calendar .fc-view {
        overflow-y: auto;
        overflow-x: hidden;
        height: 479px;
    }
    .dashboard-calendar .fc td,
    .dashboard-calendar .fc th {
        border-width: 0;
    }
}

.fc .fc-popover {
    z-index: 1040 !important;
}
