.checkbox label {
    cursor: pointer;
}

.checkbox {
    padding-left: 20px;
}

.checkbox label {
    display: block;
    padding-left: 5px;
    position: relative;
}

.checkbox label::before {
    background-color: #fff;
    border-radius: 1px;
    border: 1px solid #e9ecef;
    content: "";
    display: inline-block;
    height: 17px;
    left: 0;
    top: 3px;
    margin-left: -20px;
    position: absolute;
    transition: 0.3s ease-in-out;
    width: 17px;
    outline: 0;
}

.checkbox label::after {
    color: #263238;
    display: inline-block;
    font-size: 11px;
    height: 16px;
    left: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    position: absolute;
    top: 3px;
    width: 16px;
}

.checkbox input[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
    z-index: 1;
    position: absolute;
    left: 0;
    outline: 0;
}

.checkbox input[type="checkbox"]:disabled + label {
    opacity: 0.65;
}

.checkbox input[type="checkbox"]:focus + label::before {
    outline-offset: -2px;
    outline: 0;
}

.checkbox input[type="checkbox"]:checked + label::after {
    @include fa-icon-solid($fa-var-check);
}

.checkbox input[type="checkbox"]:disabled + label::before {
    background-color: #ebf3f5;
    cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
    border-radius: 50%;
}

.checkbox.checkbox-inline {
    margin-top: 0;
}

.checkbox.checkbox-single label {
    height: 17px;
}

.checkbox-primary input[type="checkbox"]:checked + label::before {
    background-color: #7460ee;
    border-color: #7460ee;
}

.checkbox-primary input[type="checkbox"]:checked + label::after {
    color: #fff;
}

.checkbox-danger input[type="checkbox"]:checked + label::before {
    background-color: #f62d51;
    border-color: #f62d51;
}

.checkbox-danger input[type="checkbox"]:checked + label::after {
    color: #fff;
}

.checkbox-info input[type="checkbox"]:checked + label::before {
    background-color: $color-primary;
    border-color: $color-primary;
}

.checkbox-info input[type="checkbox"]:checked + label::after {
    color: #fff;
}

.checkbox-warning input[type="checkbox"]:checked + label::before {
    background-color: #ffbc34;
    border-color: #ffbc34;
}

.checkbox-warning input[type="checkbox"]:checked + label::after {
    color: #fff;
}

.checkbox-success input[type="checkbox"]:checked + label::before {
    background-color: #39c449;
    border-color: #39c449;
}

.checkbox-success input[type="checkbox"]:checked + label::after {
    color: #fff;
}

.checkbox-purple input[type="checkbox"]:checked + label::before {
    background-color: #7460ee;
    border-color: #7460ee;
}

.checkbox-purple input[type="checkbox"]:checked + label::after {
    color: #fff;
}

.checkbox-red input[type="checkbox"]:checked + label::before {
    background-color: #f62d51;
    border-color: #f62d51;
}

.checkbox-red input[type="checkbox"]:checked + label::after {
    color: #fff;
}

.checkbox-inverse input[type="checkbox"]:checked + label::before {
    background-color: #2f3d4a;
    border-color: #2f3d4a;
}

.checkbox-inverse input[type="checkbox"]:checked + label::after {
    color: #fff;
}

.custom-switch .custom-control-label::after {
    top: calc(.25rem + 0px);
    cursor: pointer !important;
}
