.address-suggest {
    li {
        line-height: 1.25; 
        color: #495057; 
        font-size: 0.875rem;
        padding: 0.125rem 0.25rem;

        div {
            &:not(:last-of-type) {
                border-bottom: 1px solid $pretty--color-default;
            }

            &.ui-state-active {
                background: $color-primary !important;
                border-color: $color-primary-dark !important;
            }
        }
    }
}