.site-heading {
    margin-bottom: 60px;
    overflow: hidden;
    margin-top: -5px;
}

.site-heading h2 {
    display: block;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.site-heading h2 span {
    color: $color-secondary;
}

.site-heading h4 {
    display: inline-block;
    padding-bottom: 20px;
    position: relative;
    z-index: 1;
}

.site-heading h4::before {
    background: $color-secondary none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 2px;
    left: 50%;
    margin-left: -25px;
    position: absolute;
    width: 50px;
}

.site-heading h2 span {
    color: $color-secondary;
}

.pricing-area .site-heading {
    margin-bottom: 4rem;
}

.pricing-item {
    background: #ffffff none repeat scroll 0 0;
    box-shadow: 0 0 10px #ccc;
    margin-bottom: 80px;
    position: relative;
    z-index: 9;
}

.pricing-item .icon {
    border-radius: 50%;
    font-size: 50px;
    height: 100px;
    left: 50%;
    line-height: 100px;
    margin-left: -50px;
    margin-top: -50px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100px;
}

.pricing-item .icon::after {
    background: #fff none repeat scroll 0 0;
    border-radius: 50%;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.pricing-item.active .icon::after {
    background: $color-secondary none repeat scroll 0 0;
    border-radius: 50%;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.pricing-item.active .icon i {
    color: #ffffff !important;
}

.pricing-item .icon i {
    color: $color-secondary;
    cursor: inherit !important;
}

.pricing-header h4 {
    font-weight: 600;
    text-transform: uppercase;
    color: #323a45;
}

.pricing-header h2 {
    color: #323a45;
    font-size: 36px;
    font-weight: 900;
    letter-spacing: -1px;
    line-height: 1;
    margin-bottom: 0;
}

.pricing-header h2 sup {
    font-size: 20px;
    font-weight: 500;
    top: 0;
}

.pricing-header h2 sub {
    font-size: 18px;
    font-weight: 400;
    margin-left: 4px;
    top: 0;
}

.pricing-item .pricing-header span {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    text-transform: uppercase;
}

.pricing-header {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 20px !important;
    padding: 50px 30px 30px !important;
}

.pricing-item .footer {
    padding: 20px 30px 30px;
}

.pricing-item li {
    font-family: "Poppins", sans-serif;
    line-height: 40px;
    margin: 0 30px;
}

.pricing-item {
    margin-bottom: 0px;
    height: 100%;
}

.pricing-item li i {
    color: #999;
    margin-left: 2px;
    margin-right: 5px;
}

.pricing-item li i:hover {
    cursor: help;
}

.pricing-item li i.fa-times {
    color: #e22626;
}

.pricing-header {
    text-align: center !important;
    margin: 0px;
}
.pricing-item-2 .pricing-header {
    background: #f2f7f8;
    margin: 0px;
}
.pricing-item-2 .icon {
    background: #f2f7f8;
}
.pricing-item .pricing-header h3 {
    font-size: 1.25rem;
    color: #fff;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 5px;
}
.pricing-header h3 sub {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 300;
    margin-left: 4px;
    top: 0;
}
.pricing-item li {
    text-align: left;
    line-height: 25px;
}
.special-list li {
    position: relative;
    padding-left: 20px;
}
.special-list li:before {
    content: "";
    width: 12px;
    height: 10px;
    position: absolute;
    background-image: url(/assets/images/check.svg);
    background-size: cover;
    background-position: center;
    left: 0;
    top: 7px;
}
.pricing .col-lg-4 {
    margin-bottom: 100px;
}

.pricing-area .pricing-item.active .pricing-header {
    background: $color-secondary none repeat scroll 0 0;
    border-color: transparent;
    margin: 0;
}

.pricing-area.color-yellow .pricing-item.active .pricing-header {
    background: $color-secondary none repeat scroll 0 0;
}

.pricing-area .pricing-item.active .pricing-header h2,
.pricing-area .pricing-item.active .pricing-header h4,
.pricing-area .pricing-item.active .pricing-header span {
    color: #ffffff;
}

.pricing-area .pricing-item.active .pricing-header span.badge {
    background: #ffffff none repeat scroll 0 0;
    color: #323a45;
}

#gopay-payment-button input[type="checkbox"] {
    display: none;
}

#gopay-payment-button input[type="checkbox"] + label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    font: 14px/20px $font-primary;
    color: #ddd;
    cursor: pointer;
    user-select: none;
}

#gopay-payment-button input[type="checkbox"] + label:last-child {
    margin-bottom: 0;
}

#gopay-payment-button input[type="checkbox"] + label:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid #6cc0e5;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.6;
    transition: all 0.12s, border-color 0.08s;
}

#gopay-payment-button input[type="checkbox"]:checked + label:before {
    width: 10px;
    top: -5px;
    left: 5px;
    border-radius: 0;
    opacity: 1;
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotate(45deg);
}

#gopay-payment-button .logo {
    margin: 0 0 0 10px;
}

#gopay-payment-button .logo img {
    width: 25px;
}

#gopay-payment-button .info {
    font-weight: 400;
    font-style: normal;
    position: relative;
    margin-left: 10px;
    color: #fff;
}
