.navbar-collapse {
    background: $color-secondary-dark !important;
    background: linear-gradient(to right, $color-secondary-dark 0, $color-secondary 100%) !important;
}

.navbar-nav .rounded-circle {
    background-color: #fff !important;
}

#main-wrapper[data-sidebartype="iconbar"] .navbar-brand .logo-text {
    display: none;
}

#main-wrapper[data-sidebartype="full"] .navbar-brand .logo-text {
    display: inline-block;
}

.nav-pills.custom-pills .nav-link {
    border-radius: 0;
    opacity: 0.7;
}

.nav-pills.custom-pills .nav-link h4 {
    line-height: 1.5rem;
    padding: 0;
    margin: 0;
}

.nav-pills.custom-pills .nav-link.active {
    color: $color-primary;
    background-color: transparent;
    border-bottom: 2px solid $color-primary;
    opacity: 1;
}

@include media-breakpoint-down(sm) {
    .navbar-dark .navbar-nav .nav-link {
        color: rgba(0, 0, 0, 0.5) !important;
    }
    .navbar-dark .navbar-nav .nav-link:hover,
    .navbar-dark .navbar-nav .nav-link[aria-expanded="true"] {
        color: rgba(0, 0, 0, 0.75) !important;
    }

    .navbar-brand .logo-text {
        display: none;
    }
}
