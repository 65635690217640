@keyframes blow {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
        opacity: 1;
        transform: scale3d(1, 1, 0.5);
    }
    50% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
        opacity: 1;
        transform: scale3d(1, 1, 0.5);
    }
    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
        opacity: 0;
        transform: scale3d(1, 1, 0.5);
    }
}

@keyframes lds-ripple {
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0;
    }
}

@keyframes heartbeat {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    25% {
        opacity: 0.1;
        transform: scale(0.1);
    }
    50% {
        opacity: 0.3;
        transform: scale(0.5);
    }
    75% {
        opacity: 0.5;
        transform: scale(0.8);
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}

@keyframes sp-anime {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}

@keyframes placeholder-glow {
    50% {
        opacity: 0.2;
    }
}
