.select2-selection--single {
    min-height: 40px;
    padding: 6px 8px;
}

.select2-search__field {
    height: 25px !important;
}

.select2-container--default.select2-container--focus .select2-selection {
    border-color: #232148 !important;
    box-shadow: 0 0 0.2rem 0.2rem rgba(103, 40, 118, 0.25) !important;
    outline-color: transparent;
    outline-width: 2px;
    outline-style: dotted;
}

.select2-container-fix {
    height: auto !important;
    padding: 0 !important;
    border: 0 !important;
}

.select2-container-fix .select2-selection__rendered,
.select2-container-fix .select2-selection {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.select2-container-fix .select2-selection__rendered {
    padding-left: 2px !important;
}

.select2-container--classic .select2-selection--single,
.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__arrow,
.select2-container--default .select2-selection--single .select2-selection__rendered {
    border-color: #ced4da;
    border-radius: 0.25rem;
    color: #54667a;
    height: 40px;
    line-height: 26px;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: #6a2c73;
}

.select2-container--default .select2-selection--multiple {
    line-height: 27px;
    height: auto;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice,
.select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    background-color: $color-primary;
    border-color: $color-primary;
    color: #fff;
}
